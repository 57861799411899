<template>
	<main-container :showHeader="false">
		<div class="d-flex flex-row align-items-center">
			<div class="mr-1">
				<small>宽度</small>
				<el-input-number v-model="canvasWidth" size="mini"></el-input-number>
			</div>
			<div class="mr-1">
				<small>高度</small>
				<el-input-number v-model="canvasHeight" size="mini"></el-input-number>
			</div>
			<div class="mr-auto d-flex flex-row align-items-center">
				<small>背景色</small>
				<el-color-picker v-model="canvasBgColor" size="mini"></el-color-picker>
			</div>
			<el-upload
			  :show-file-list="false"
			  action="/"
			  accept=".json"
			  :before-upload="beforeUploadCsv">
			  <el-button slot="trigger" size="mini" type="text">导入更多模板</el-button>
			</el-upload>
			<el-button type="text" class="text-success mx-1" @click="exportExample" size="mini">导出样例</el-button>
			<el-dropdown @command="selTep">
			      <span class="el-dropdown-link" style="font-size:12px;line-height:28px;height:28px;cursor:pointer;">
			        选择内置模板
			      </span>
			      <el-dropdown-menu slot="dropdown">
			        <el-dropdown-item v-for="tep in teps" :command="tep">{{tep}}</el-dropdown-item>
			      </el-dropdown-menu>
			</el-dropdown>
		</div>
		<div class="d-flex flex-row mb-2">
			<el-upload
			  :show-file-list="false"
			  action="/"
			  accept="image/*"
			  :before-upload="beforeUpload">
			  <el-button slot="trigger" size="mini" type="text">添加图片</el-button>
			</el-upload>
			<el-dropdown @command="editImg" :disabled="images.length===0" class="mx-2">
			  <el-button size="mini" type="text">编辑图片</el-button>
			  <el-dropdown-menu slot="dropdown">
			    <el-dropdown-item v-for="(image, index) in images" :command="image.id">
					<small>{{index}}. {{image.name}}</small>
				</el-dropdown-item>
			  </el-dropdown-menu>
			</el-dropdown>
			<el-button type="text" @click="saveImg" size="mini">保存</el-button>
			<el-button type="text" class="text-danger" @click="resetTep" size="mini">重置</el-button>
		</div>
		<div class="text-center">
			<div id="custom-canvas" :style="canvasStyle">
				<editor-img v-for="image in images" :key="image.id" :image="image" @editImg="editImg"></editor-img>
			</div>
		</div>
		<el-drawer
		  size="400px"
		  title="图片编辑"
		  :visible.sync="drawer"
		  direction="rtl"
		  :before-close="handleClose">
		  <div class="d-flex flex-column p-3" v-if="selIndex > -1">
			  <small v-if="selImg.name">图片名</small>
			  <small>{{selImg.name}}</small>
			  <small>容器样式</small>
			  <form-item label="宽度">
				  <el-input size="mini" v-model="boxCss.width"></el-input>
			  </form-item>
			  <form-item label="高度">
				<el-input size="mini" v-model="boxCss.height"></el-input>
			  </form-item>
			  <form-item label="距顶">
			  	<el-input size="mini" v-model="boxCss.top"></el-input>
			  </form-item>
			  <form-item label="距左">
			  	<el-input size="mini" v-model="boxCss.left"></el-input>
			  </form-item>
			  <form-item label="距右">
			  	<el-input size="mini" v-model="boxCss.right"></el-input>
			  </form-item>
			  <form-item label="距底">
			  	<el-input size="mini" v-model="boxCss.bottom"></el-input>
			  </form-item>
			  <small>图片</small>
			  <form-item label="缩放">
				<el-input-number size="mini" v-model="params.zoomVal" :min="0.2" :step="0.1"></el-input-number>
			  </form-item>
			  <form-item label="距左">
				<el-input-number size="mini" v-model="params.left"></el-input-number>
			  </form-item>
			  <form-item label="距顶">
				  <el-input-number size="mini" v-model="params.top"></el-input-number>
			  </form-item>
			   <div class="mt-2 d-flex flex-row align-content-center justify-content-around">
				   <el-button type="success" @click="confirm('apply')" size="mini">应用</el-button>
				   <el-button type="primary" @click="confirm('cancel')" size="mini">取消</el-button>
				   <el-button type="danger" @click="confirm('delete')" size="mini">删除</el-button>
			   </div>
		  </div>
		</el-drawer>
	</main-container>
</template>

<script>
	const InitialParams = {
		zoomVal: 1,  // 图片缩放倍率
		left: 0,
		top: 0,
		currentX: 0,
		currentY: 0,
		flag: false
	}
	import { uuid } from 'vue-uuid';
	import Common from '@/common/mixins/common.js'
	import html2canvas from 'html2canvas'
	import {fetchJSONByPost} from '@/apis'
	import EditorImg from '@/components/image-editor/index.vue'
	import ImageEditorTeps from '@/assets/json/image-editor-teps.json'
	export default {
		mixins: [Common],
		inject: ['adminLayout'],
		components: {
			EditorImg
		},
		provide() {
		    return {
		      parseFile: this.parseFile
		    }
		},
		data() {
			return {
				images: [],
				selImg: {},
				selIndex: -1,
				drawer: false,
				boxCss: {},
				params: {},
				canvasWidth: 500,
				canvasHeight: 500,
				canvasBgColor: '#FFFFFF'
			}
		},
		computed: {
			canvasStyle() {
				return {
					width:this.canvasWidth + 'px',
					height:this.canvasHeight + 'px',
					backgroundColor: this.canvasBgColor
				}
			},
			teps() {
				return ImageEditorTeps.map(v => v.name)
			}
		},
		watch: {
			boxCss: {
				handler(val){
					this.images[this.selIndex].boxCss = {...val}
				},
				deep: true
			},
			params: {
				handler(val) {
					this.images[this.selIndex].params = {...val}
				},
				deep: true
			}
		},
		methods: {
			handleTep(tep) {
				const {canvasBgColor,canvasHeight, canvasWidth, images} = tep || {}
				if (Array.isArray(images) && images.length > 0 && isNaN(canvasHeight) === false && isNaN(canvasWidth) === false) {
					this.canvasBgColor = canvasBgColor || '#FFFFFF'
					this.canvasHeight = canvasHeight || 1000
					this.canvasWidth = canvasWidth || 1000
					this.images = (images || []).map(v => {
						return {
							...v,
							id: uuid.v4(),
							src: /^data/.test(v.src || '') ? v.src : '',
							params: {...InitialParams}
						}
					})
				} else {
					this.$showErrMsg('无法识别此模板')
				}
			},
			beforeUploadCsv(file) {
				const reader = new FileReader();
				reader.onload = (event) => {
					const tep = JSON.parse(event.target.result)
					this.handleTep(tep)
				}
				reader.readAsText(file)
				return false
			},
			parseFile(file, callback) {
				const reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = (res) => {
					if (res && res.target && res.target.result) {
						const img = new Image()
						img.onload = () => {
							const {
								width,
								height
							} = img
							if (typeof callback === 'function') {
								callback(width, height, file.name, res.target.result, {...InitialParams})
							} else {
								const newImg = {
									src: res.target.result,
									name: file.name,
									width,
									height,
									id: uuid.v4(),
									boxCss: {
										"left":"0px",
										"top":"0px",
										"width": width + "px",
										"height": height + "px"
									},
									params: {...InitialParams}
								}
								this.images.push(newImg)
								console.log(this.images)
							}
						}
						img.src = res.target.result
					}
				}
			},
			beforeUpload(file) {
				this.parseFile(file)
				return false
			},
			delImg(id) {
				this.images = this.images.filter(v => v.id === id)
			},
			editImg(id) {
				const selIndex = this.images.findIndex(v => v.id === id)
				if (selIndex !== -1) {
					this.selIndex = selIndex
					this.selImg = {
						...this.images[selIndex],
						boxCss: {
							...this.images[selIndex].boxCss
						}
					}
					this.boxCss = {
						width: 'unset',
						height: 'unset',
						top: 'unset',
						bottom: 'unset',
						left: 'unset',
						right: 'unset',
						...this.images[selIndex].boxCss
					}
					this.params = {
						...this.images[selIndex].params
					}
					this.drawer = true
				}
			},
			confirm(action) {
				switch (action) {
					case 'cancel':
						this.images[this.selIndex] = this.selImg
					case 'apply':
						this.selIndex = -1
						this.drawer = false
						break;
					case 'delete':
						this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.images.splice(this.selIndex, 1)
							this.selIndex = -1
							this.drawer = false
						})
						break
				}
			},
			saveImg() {
				html2canvas(document.querySelector('#custom-canvas')).then(canvas => {
					var fullQuality = canvas.toDataURL('image/jpeg', 1.0)
					this.adminLayout.showLoading()
					this.urltoFile(fullQuality)
					.then(file => {
						var body = new FormData()
						body.append('file', file)
						const headers = {
							"Content-Type": "multipart/form-data"
						}
						fetchJSONByPost('/adms_api_v0/media/file', body, {headers})
							.then((data) => {
								this.adminLayout.hideLoading()
								this.$showSuccess(JSON.stringify(data))
							})
							.catch(err => {
								this.adminLayout.hideLoading()
								this.$showErrMsg(err)
							})
					})
				})
			},
			urltoFile(url, filename = 'saved.jpeg', mimeType = 'image/jpeg'){
			    return (fetch(url)
			        .then(function(res){return res.arrayBuffer();})
			        .then(function(buf){return new File([buf], filename,{type:mimeType});})
			    );
			},
			exportExample() {
				this.downloadFile('/example/json/img-tep-example.json')
			},
			resetTep() {
				this.images = []
				this.canvasWidth = 500
				this.canvasHeight = 500
				this.canvasBgColor = '#FFFFFF'
			},
			selTep(command) {
				const config = ImageEditorTeps.find(v => v.name === command)
				if (config) {
					const {tep} = config
					this.handleTep(tep)
				}
			}
		}
	}
</script>

<style lang="scss">
	#custom-canvas {
		position:relative;
		overflow: hidden;
		display: inline-block;
	}
</style>
