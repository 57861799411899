<template>
	<div :style="image.boxCss" class="image-editor-box-container" @mousewheel="changeSize" @dblclick="updateImg">
		<img :id="'editor-img-' + image.id" v-show="image.src" :src="image.src" :width="image.width" :height="image.height" class="editor-img-default"  @mousedown="mousedown" @mouseup="mouseup" @mousemove="mousemove" />
		<el-upload v-show="!image.src" :show-file-list="false" action="/" accept="image/*" :before-upload="beforeUpload" :id="'editor-img-picker-' + image.id" class="image-editor-picker">
			<div slot="trigger" class="el-upload--picture-card">
				<i class="el-icon-plus"></i>
			</div>
		</el-upload>
	</div>
</template>

<script>
	const InitialParams = {
		zoomVal: 1,  // 图片缩放倍率
		left: 0,
		top: 0,
		currentX: 0,
		currentY: 0,
		flag: false
	}
	export default {
		props: {
			image: {
				required: true,
				type: Object
			}
		},
		inject: ['parseFile'],
		watch: {
			'image.params.zoomVal'(newVal, oldVal){
				const el = document.querySelector('#editor-img-' + this.image.id)
				el.style.transform = "scale(" + newVal + ")";
			},
			'image.params.left'(newVal, oldVal) {
				const el = document.querySelector('#editor-img-' + this.image.id)
				el.style.left = parseInt(newVal) + "px"
				console.log('left>>',newVal)
			},
			'image.params.top'(newVal, oldVal) {
				const el = document.querySelector('#editor-img-' + this.image.id)
				el.style.top = parseInt(newVal) + "px"
				console.log('top>>>',newVal)
			}
		},
		mounted() {
			// Prevent double click trigger twice popup
			document.querySelector(`#editor-img-picker-${this.image.id} .el-upload--picture-card`).onclick = () => {
				document.querySelector(`#editor-img-picker-${this.image.id} input`).click();
				document.querySelector(`#editor-img-picker-${this.image.id} input`).setAttribute('disabled',true);
				setTimeout(() => {
					document.querySelector(`#editor-img-picker-${this.image.id} input`).removeAttribute('disabled');
				}, 1000);
			};
		},
		methods: {
			beforeUpload(file) {
				this.parseFile(file, (width, height, name, src, params) => {
					this.image.name = name
					this.image.src = src
					this.image.width = width
					this.image.height = height
					this.image.params = params
				})
				return false
			},
			changeSize(event) {
				if (!this.image.src) return false
				event.preventDefault()
				const img = document.querySelector('#editor-img-' + this.image.id)
				var zoomVal = this.image.params.zoomVal
				zoomVal += event.wheelDelta / 1200
				this.image.params.zoomVal = Math.max(0.2, zoomVal)
				return this.image.params.zoomVal > 0.2
			},
			getCss(o, key) {
			    return o.currentStyle ? o.currentStyle[key] : document.defaultView.getComputedStyle(o, false)[key];
			},
			mousedown(event) {
			    this.image.params.flag = true
			    var e = event;
			    this.image.params.currentX = e.clientX
			    this.image.params.currentY = e.clientY
			},
			mouseup(event) {
			    // 鼠标放开
			    this.image.params.flag = false;  // 设置为不可移动状态
			    // 将现在的位置记录下来
			    if (this.getCss(event.target, "left") !== "auto") {
			        this.image.params.left = parseInt(this.getCss(event.target, "left"))
			    }
			    if (this.getCss(event.target, "top") !== "auto") {
			        this.image.params.top = parseInt(this.getCss(event.target, "top"))
			    }
				console.log(this.image.params)
			},
			mousemove(event) {
				if (this.image.params.flag) {
					console.log(JSON.stringify(this.image.params))
					var e = event ? event : window.event;
				    // 计算出鼠标按下到移动过程中的鼠标相对位移
				    var nowX = e.clientX, nowY = e.clientY;
				    var disX = nowX - this.image.params.currentX, disY = nowY - this.image.params.currentY;
				    event.target.style.left = parseInt(this.image.params.left) + disX + "px";
				    event.target.style.top = parseInt(this.image.params.top) + disY + "px";
				    // 不要执行与事件关联的默认动作
				    if (event.preventDefault) {
				        event.preventDefault();
				    }
				}
			},
			updateImg(e) {
				if (/^data/.test(this.image.src)) {
					document.querySelector(`#editor-img-picker-${this.image.id} .el-upload--picture-card`).click()
				}
				e.preventDefault()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.image-editor-box-container {
		overflow: hidden;
		position: absolute;
	}
	.image-editor-box-container:hover {
		border: 1px solid #ddd;
		box-sizing: border-box;
	}
	.image-editor-picker {
		width:100%;
		height:100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.el-upload {
			display: block;
			width: 100%;
			height: 100%;
			.el-upload--picture-card {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.editor-img-default {
		position: absolute;
		height: auto;
		width: 100%;
		cursor: pointer;
		left: 0;
		top: 0;
	}
</style>
